





















import i18n from '@/i18n';
import { AuthGetters } from '@/store/modules/auth/enums';
import { Component, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';
import CustomerInfo from '@/store/modules/auth/authState';
import { getContrastText } from '@/utils/colors.utils';

@Component
export default class ProfileMenu extends Vue {
  public get language(): string {
    return i18n.locale ?? 'en';
  }

  account(): void {
    this.$emit('gotoaccount');
  }

  logout(): void {
    this.$emit('logout');
  }

  get customerInfo(): CustomerInfo {
    return this.$store.getters[AuthGetters.GetCustomerInfo];
  }

  get merchantConfig(): MerchantConfig {
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  contrastColor(color: string): string {
    return getContrastText(color);
  }
}
