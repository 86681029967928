














import { AuthActions } from '@/store/modules/auth/enums';
import { Component, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { Routes } from '@/router/routes';
import Header from '@/components/layout/Header.vue';
import HeaderBackground from '@/components/layout/HeaderBackground.vue';
import MobileAppRegistrationForm from './MobileAppRegistrationForm.vue';
import { isNullOrWhiteSpace } from '@glitchedmob/isnullorempty';
import { urlQueryService } from '@/services/common/urlquery.service';

@Component({
  components: {
    'ck-header': Header,
    'ck-header-background': HeaderBackground,
    'ck-mobileapp-registration-form': MobileAppRegistrationForm,
  },
})
export default class MobileAppRegistration extends Vue {
  protected loading = true;
  protected merchantConfig: MerchantConfig = new MerchantConfig();

  public async created(): Promise<void> {
    let hash = this.retrieveHash();
    await this.retrieveMerchantCfg(hash);
  }

  private async retrieveMerchantCfg(hash?: string): Promise<void> {
    this.$spinner.showSpinner();
    await this.$store
      .dispatch(AuthActions.CheckMobileHash, hash)
      .then((response: MerchantConfig) => {
        this.merchantConfig = response;
      })
      .catch(() => {
        this.$router.push({ name: Routes.Home });
        this.$spinner.removeSpinner();
        this.$toaster.showError('dashboard.invalid-mobile-app-hash', true);
      })
      .finally(() => {
        this.loading = false;
        this.$spinner.removeSpinner();
      });
  }

  private retrieveHash(): string | undefined {
    let hash = urlQueryService.getMobileAppRegistrationQuery()?.hash;
    if (isNullOrWhiteSpace(hash)) {
      this.$router.push({ name: Routes.Home });
      return undefined;
    }
    return hash;
  }
}
