




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';

@Component
export default class HeaderBackground extends Vue {
  @Prop()
  public merchantConfig!: MerchantConfig;

  getColor(): string {
    const defaultColor = '#a3262b';
    if (this.merchantConfig && this.merchantConfig.color)
      return this.merchantConfig.color;
    let merchantConfig =
      this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
    if (merchantConfig && merchantConfig.color) return merchantConfig.color;
    return defaultColor;
  }
}
