




























import i18n from '@/i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonActions } from '@/store/modules/common/enums';
import { getContrastText } from '@/utils/colors.utils';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';

@Component
export default class LanguageSelector extends Vue {
  @Prop()
  public merchantCfg!: MerchantConfig;

  public get language(): string {
    return i18n.locale ?? 'en';
  }

  setLanguage(newLanguage: string): void {
    this.$store.dispatch(CommonActions.SetLanguage, newLanguage);
  }

  get merchantConfig(): MerchantConfig {
    if (this.merchantCfg) {
      return this.merchantCfg;
    }
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  public contrastColor(): string {
    return getContrastText(this.merchantCfg?.color);
  }
}
