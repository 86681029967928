

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';
import { Routes } from '@/router/routes';
import LanguageSelector from '@/components/common/LanguageSelector.vue';
import ProfileMenu from '@/components/common/ProfileMenu.vue';
import { msalPluginInstance } from '@/plugin/MsalPlugin';

@Component({
  components: {
    'ck-language-selector': LanguageSelector,
    'ck-profile-menu': ProfileMenu,
  },
})
export default class Header extends Vue {
  @Prop()
  public merchantCfg!: MerchantConfig;
  @Prop({ default: true })
  public homeRedirect!: boolean;
  @Prop({ default: true })
  public showProfile!: boolean;
  protected logged = msalPluginInstance.isUserAuthenticated();

  get merchantConfig(): MerchantConfig {
    if (this.merchantCfg) {
      return this.merchantCfg;
    }
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  account(): void {
    this.$emit('gotoaccount');
  }

  logout(): void {
    this.$emit('logout');
  }

  public mainRoute(): string {
    if (this.logged) return Routes.Customer;
    return Routes.Home;
  }
}
