export const getContrastText = (hexColor: string | undefined): string => {
  if (typeof hexColor === 'undefined') return 'text-white';
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 128 ? 'text-white' : 'text-black';
};

export const fixMerchantColor = (hexColor: string | undefined): string => {
  if (typeof hexColor === 'undefined') return '#606060';
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 128 ? hexColor : '#606060';
};
